import { Component, OnInit, ViewChild, HostBinding } from "@angular/core";
import { DataStoreService } from "../shared/services/datastore.service";
import { fadeInAnimation } from "../shared/animations/fade-in.animation";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { Layout, Column, Toolbar } from "../shared/model/layout";
import { MatMenuTrigger } from "@angular/material/menu";
import { AppConfig } from "../../environments/environment";
import { Log } from "../shared/Logger";

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from "@angular/cdk/drag-drop";
import { timingSafeEqual } from "crypto";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
  animations: [fadeInAnimation]
})
export class MainComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  prevMenu: MatMenuTrigger;
  contextMenuPosition = { x: "0px", y: "0px" };

  @HostBinding("@fadeInAnimation") fadeInAnimation = true;
  columns: Column[];
  toolbar: Toolbar;
  textColor: string;
  // adminMode = false;
  showButtons = 1;
  showContent = false;
  showDialog = false;
  showOverlay = false;
  showSettings = false;
  language;
  currentLang;
  configService: any = {};
  selected: any = {};
  groups = [];
  url: string;
  // el: ElementRef;
  availableLanguages: Array<any> = [
    { value: "en", label: "Englishasd" },
    { value: "bg", label: "Български" },
    { value: "fr", label: "Français" }
  ];

  constructor(
    public data: DataStoreService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    // console.log(data);
    this.columns = data.store.columns;
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    this.textColor = data.store.textColor;
    this.toolbar = this.data.store.toolbar;
    this.url = AppConfig.imageBase;
  }

  ngOnInit(): void {
    this.configService.selectedGroup = 1;
    this.showContent = true;
    this.changeLang(navigator.language.split("-")[0]);

    document.addEventListener("click", (offEvent: any) => {
      // offEvent.preventDefault();
      this.contextMenu.closeMenu();
    });
  }

  logoClick(url) {
    window.open("https://" + url, "_blank");
  }

  reloadApp() {
    window.location.reload();
  }

  drop(event: CdkDragDrop<string[]>) {
    // console.log("columns");
    // console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onDragStart(event) {
    console.log(event);
  }

  toggleSettings() {
    this.showSettings = !this.showSettings;
    this.showOverlay = !this.showOverlay;
  }

  openDropbox() {}

  openAdminView() {
    this.data.adminMode = !this.data.adminMode;
    this.data.checkFor_hidden_tiles(0);
    // console.log(this.adminMode);
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
    this.showOverlay = !this.showOverlay;
  }

  signOut() {
    let action = {
      scheme: "cmd",
      method: "signOut",
      parameters: {}
    };
    this.toggleDialog();
  }

  changeLang(lang: string) {
    try {
      this.translate.use(lang);
      this.currentLang = lang;
      // this.electronService.ipcRenderer.send(
      //   "lang-change",
      //   ElectronLogger.FormLogMessage("Language Changed:" + lang)
      // );
    } catch (error) {
      // this.logError(error);
    }
  }

  changeGroup(e) {
    console.log(e);
  }
  clearCache() {
    Log.log("cache");
    try {
      Log.send("clear-cache", "Cache cleared");
    } catch (error) {
      Log.log(error);
    }
  }

  removeItemShortcut(item) {
    item.hasShortcut = false;
    localStorage.setItem(item.id, "");
    this.data.checkFor_hidden_tiles(0);
  }
  addItemShortcut(item) {
    item.hasShortcut = true;
    localStorage.setItem(item.id, "1");
    // this.data.checkFor_hidden_tiles(0);
  }

  onContextMenu(e) {
    console.log(e);
    this.contextMenu.closeMenu();
    event.preventDefault();
    this.contextMenuPosition.x = e.event.clientX + "px";
    this.contextMenuPosition.y = e.event.clientY + "px";
    this.contextMenu.menuData = { item: e.item, expandMode: e.expandMode };
    this.contextMenu.menu.focusFirstItem("mouse");

    setTimeout(() => {
      this.contextMenu.openMenu();
    }, 150);
  }

  openDialog(event) {
    console.log(event);
    this.selected.item = event.item;
    this.selected.xtype = "tile";
  }

  setTileSubType(event) {}
  setSelectedFile(file) {}
  openLibrary() {}
  cancelEdit() {
    console.log("cancel");
    this.selected = {};
  }
}
