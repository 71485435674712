import { TileAction } from "./TileAction";
export class Tile {
  title: string;
  sortOrder: number;
  backgroundColor: string;
  imageURL: string;
  size: string;
  action: TileAction;
  description: string;
  hasShortcut: boolean;
  stype: string;
  layout: string;
  imageFit: string;
  backgroundFit: string;

  constructor() {
    this.description = " new Item ";
    this.title = " ... ";
    this.sortOrder = 1;
    this.imageURL = "/api/v1/icons/newItem";
    this.backgroundColor = "#0000";
    this.layout = "border";
    this.imageFit = "fill";
    this.backgroundFit = "fill";
    this.size = "small";
    this.hasShortcut = true;
    this.stype = "";
  }
}
