import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { RssService } from "../../shared/rss.service";
// import {OpenAction} from "../../shared/model/actions/OpenAction";
// import { ToastrService } from 'ngx-toastr';
// import { NgxElectronService } from 'src/app/ngx-electron/ngx-electron.service';
// import { ElectronLogger } from 'src/app/shared/ElectronLogger';

@Component({
  selector: "rss-feed-tile",
  templateUrl: "./rss-feed.component.html",
  styleUrls: ["./rss-feed.component.scss"]
})
export class RssFeedComponent implements OnInit {
  @Input() channelUrl: string;
  // @Output() onTileClick: EventEmitter<any> = new EventEmitter();
  // @Output() onLinkClick: EventEmitter<any> = new EventEmitter();
  channel: any;
  data;
  rss;
  channelLoaded: boolean = false;
  slideID = 1;

  constructor(public rssService: RssService) {}

  ngOnInit() {
    this.getChannel();
  }

  getChannel() {
    this.rssService.getFeed("/api/v1/rss/" + this.channelUrl).subscribe(
      data => {
        this.data = data.channel;
        this.channel = data.title;
        this.rss = this.data[0];
        console.log(this.channel);
        this.channelLoaded = true;
        this.setSlides();
      },
      (error: any) => {
        this.logError(error);
      }
    );
  }

  setSlides() {
    setInterval(() => {
      this.slideID += 1;
      if (this.slideID == this.data.length) this.slideID = 0;
      this.rss = this.data[this.slideID];
    }, 5000);
  }

  linkClicked(link) {
    try {
      // let action = OpenAction.init();
      // action.parameters.fileUri = link.link;
      // action.parameters.windowName = link.title;
      // this.onLinkClick.emit(action);
    } catch (error) {
      this.logError(error);
    }
  }

  private logError(error: any) {
    // this.showError(error);
    // console.error(error);
    // this.electronService.ipcRenderer.send('error', ElectronLogger.FormLogMessage(error.json()));
  }

  showError(msg) {
    // this.toastrService.error(msg)
  }
}
