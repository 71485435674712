import { transition, style, trigger, animate, stagger, query } from "@angular/animations";
export let appearAnimation = trigger('appearAnimation', [
    transition('* => *', [
        query('.animated-element', style({transform: 'translateX(-5%)', opacity: 0}), {optional: true}),
        query('.animated-element',
            stagger('30ms', [
                animate('1000ms', style({transform: 'translateX(0)', opacity: 1}))
            ]), {optional: true})
    ])
]);
