import { Injectable } from "@angular/core";

// If you import a module but never use any of the imported values other than as TypeScript types,
// the resulting javascript file will look as if you never imported the module at all.
import { ipcRenderer, webFrame, remote } from "electron";
import isElectron from "is-electron";
import * as childProcess from "child_process";
import * as fs from "fs";
import * as path from "path";
import * as process from "process";

@Injectable({
  providedIn: "root"
})
export class ElectronService {
  ipcRenderer: typeof ipcRenderer;
  webFrame: typeof webFrame;
  remote: typeof remote;
  childProcess: typeof childProcess;
  fs: typeof fs;
  path: typeof path;

  get isElectron(): boolean {
    return isElectron();
  }

  constructor() {
    // Conditional imports
    console.log("isElectron:" + isElectron());

    if (isElectron()) {
      this.ipcRenderer = window.require("electron").ipcRenderer;
      this.webFrame = window.require("electron").webFrame;
      this.remote = window.require("electron").remote;
      var winston = window.require("electron-log");
      this.childProcess = window.require("child_process");
      this.fs = window.require("fs");
      this.path = window.require("path");
    }
  }
}
