import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
@Component({
    selector: "buttonComponent",
    templateUrl: "./button.component.html",
    styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {
    @Output() buttonClick: EventEmitter<any> = new EventEmitter();   
    @Input() dataHint: string; 
    @Input() showButtons: boolean;
    @Input() class: string;
    @Input() adminUser: any = false;

    ngOnInit() {
        
    }

    handleClick() {
        this.buttonClick.emit();
    }
}