import { Injectable } from "@angular/core";
import { Layout } from "../model/layout";
// import {
//   taraItem,
//   reviziaItem,
//   cashItem,
//   Store
// } from 'app/shared/models/item.model';

import { Router, ActivatedRoute } from "@angular/router";

import { DataService } from "./data.service";

// tslint:disable-next-line: no-console
const log = console.log;

@Injectable()
export class DataStoreService {
  store: Layout;
  adminMode: boolean;

  constructor(private source: DataService, private router: Router) {
    if (!source.data) {
      this.source.UIdata.subscribe((data: Layout[]) => {
        this.store = { ...data[0] };
      });
    }
    source.data.columns = [...source.data.items[4].items];
    // source.data.items[4] = {};
    source.data.toolbar = { ...source.data.items[3] };
    source.data.items = null;
    console.log(source);
    this.checkFor_hidden_tiles(source.data);
  }

  checkFor_hidden_tiles(data) {
    if (!data) data = this.store;
    data.columns.forEach((column, idx) => {
      column.items?.forEach((group, id) => {
        // not to hide + - in admin mode after hasShortcut changes
        // if (this.adminMode)
        data.columns[idx].items[id]["hiddenTiles"] = false;
        group.items?.some(tile => {
          if (!tile.hasShortcut) {
            data.columns[idx].items[id]["hiddenTiles"] = true;
          }
        });
        if (!data.columns[idx].items[id]["hiddenTiles"] && this.adminMode)
          data.columns[idx].items[id]["expandMode"] = false;
      });
    });
    console.log("recalc");
    this.store = { ...data };
  }
}
