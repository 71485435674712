import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AppConfig } from "../../../environments/environment";
import { DataStoreService } from "../../shared/services/datastore.service";

@Component({
  selector: "tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"]
})
export class TileComponent implements OnInit {
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  // @Input() adminMode: boolean;
  @Input() item: any;
  // @Input() menuOptions: any;
  // @Input() menu: any;
  @Input() expandMode: any;
  @Input() group: any;
  @Output("editDialogOpen") onEditDialogOpen: EventEmitter<
    any
  > = new EventEmitter();

  hiddenIcons: boolean = true;
  url = "";
  constructor(public sanitizer: DomSanitizer, public data: DataStoreService) {
    //this.size = this.getTileSize(this.item.size);
    this.url = AppConfig.imageBase;
  }
  ngOnInit() {}

  ngAfterViewInit() {
    // console.log(this.item);
  }

  handleClick() {
    this.buttonClick.emit();
  }

  toggleSize() {
    switch (this.item.size) {
      case "small":
        this.item.size = "medium";
        break;
      case "medium":
        this.item.size = "wide";
        break;
      case "wide":
        //   this.item.size = "large";
        //   break;
        // case "large":
        this.item.size = "small";
        break;
      default:
        this.item.size = "small";
    }
    // console.log(this.item.size);
  }

  getTileSize(size): string {
    // console.log(size);
    if (size == "medium") {
      return "tile";
    } else {
      return "tile-" + size;
    }
  }

  setTooltip(desc) {
    let el = document.getElementsByClassName("mat-tooltip");
    if (!el?.length || !desc?.length) return;
    setTimeout(() => {
      if (el[0] != undefined) el[0].innerHTML = desc;
    }, 120);
    //
  }

  hideIcons() {
    this.hiddenIcons = !this.hiddenIcons;
  }

  editDialogOpen() {
    console.log("tile");
    this.onEditDialogOpen.emit();
  }

  getHtml(text) {
    const html = text;
    const div = document.createElement("div");
    div.innerHTML = html;
    return div;
  }

  changeItemVisibility(item) {
    console.log("chageVisibility");
    item.hasShortcut = !item.hasShortcut;
    if (!item.hasShortcut) this.data.checkFor_hidden_tiles(0);
  }
}
