import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { ColorPickerModule } from "ngx-color-picker";
import { FormsModule } from "@angular/forms";
import { MainComponent } from "./main.component";
import { ButtonComponent } from "../components/button/button.component";
import { ColumnComponent } from "../components/column/column.component";
import { TileComponent } from "../components/tile/tile.component";
import { TileBtnComponent } from "../components/tileBtn/tileBtn.component";
import { RssFeedComponent } from "../components/rss-feed/rss-feed.component";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { ContextMenuModule } from "ngx-contextmenu";
import { AppMaterialModule } from "../material.module";
import { RssService } from "../shared/rss.service";

import { MenuComponent } from "../components/menu/menu.component";

import { HttpClientModule, HttpClient } from "@angular/common/http";

// NG Translate
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { EditDialogComponent } from "../components/edit-dialog/edit-dialog.component";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FileComponent } from "../components/file/file.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    MenuComponent,
    MainComponent,
    ColumnComponent,
    RssFeedComponent,
    ButtonComponent,
    TileComponent,
    TileBtnComponent,
    FileComponent,
    // EditDialogComponent
    // MenuComponent
  ],
  imports: [
    ColorPickerModule,
    MatDialogModule,
    ContextMenuModule.forRoot(),
    AppMaterialModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    RssService,
  ],
})
export class MainModule {}
