import { transition, style, trigger, animate } from "@angular/animations";
export let fadeInAnimation = trigger('fadeInAnimation', [
    transition('void => *', [
        style({
            opacity: 0,
        }),
        animate('400ms 150ms ease-in-out', style({
            opacity: 1,
        }))
    ]),
    transition('* => void', [
        style({
            opacity: 1,
        }),
        animate('400ms 150ms ease-in-out', style({
            opacity: 0,
        }))
    ])
]);
