import { ElectronService } from "../../electron/electron.service";
const electronService = new ElectronService();

export class Log {
  constructor(private electronService: ElectronService) {}
  public static FormLogMessage(message) {
    return new Date().toISOString() + " : " + message;
  }

  public static send(msg, data) {
    this.log(msg);
    // electronService.ipcRenderer.send(msg, this.FormLogMessage(msg));
    // window["ipcRenderer"].send(msg, this.FormLogMessage(msg));
    window.postMessage({ type: msg, data: data }, "*");
  }

  public static log(data) {
    console.log(data);
  }
}
