import {
  Component,
  Input,
  Output,
  OnInit,
  ViewChild,
  EventEmitter
} from "@angular/core";
import { DataStoreService } from "../../shared/services/datastore.service";
import { appearAnimation } from "../../shared/animations/appear.animation";
import { fadeInAnimation } from "../../shared/animations/fade-in.animation";
import { MatMenuTrigger } from "@angular/material/menu";
import { TranslateService } from "@ngx-translate/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDropList
} from "@angular/cdk/drag-drop";
import { Tile, Group } from "../../shared/model/layout";

@Component({
  selector: "column-component",
  templateUrl: "./column.component.html",
  styleUrls: ["./column.component.scss"],
  animations: [appearAnimation, fadeInAnimation]
})
export class ColumnComponent implements OnInit {
  @Input() column: any;
  @Input() textColor: any = "#fff";
  // @Input()
  // adminMode: boolean;
  @Output("onContextMenu") onContextMenu: EventEmitter<
    any
  > = new EventEmitter();
  @Output("onEditDialogOpen") onEditDialogOpen: EventEmitter<
    any
  > = new EventEmitter();

  // @Output() onGroupExpand: EventEmitter<any> = new EventEmitter();
  triggerAnimation: boolean = false;
  selectedItems: Array<any> = [];
  itemSizeClass: string = "medium";
  focusedInput: string;
  showButtons: boolean = true;
  newTile = new Tile();
  newGroupTile = new Tile();
  hiddenTiles: Array<boolean> = [];
  tile_destination_index: number = 0;
  tile_destination_container: string[];
  color: string;

  constructor(
    // public actionService: ActionService,
    public data: DataStoreService,
    public translate: TranslateService // public configService: ConfigurationService, // public serverConfig: ServerConfig, // public electronService: NgxElectronService, // public toastrService: ToastrService
  ) {
    //this.textColor = "#fff";
    this.newTile.title = "add Item";
    this.newTile.imageURL = ""; //"../../../assets/images/Add_tile-512.png";
    this.newGroupTile.title = "add Group";
    this.newGroupTile.imageURL = ""; // "../../../assets/images/Add_tile-512.png";
    // this.adminMode = this.data.adminMode;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.triggerAnimation = true;
    }, 100);
  }

  logItem(item) {
    console.log(item);
  }

  makeEditable(event: any, groupID: number) {
    if (!this.data.adminMode) return;
    let el = event.target;
    el.contentEditable = "true";
    el.style.cursor = "text";
    el.focus();
  }

  saveChanges(event, groupID: number) {
    event.target.contentEditable = "false";
    event.target.style.cursor = "pointer";
    event.preventDefault();
    if (!this.data.adminMode) return;
    const newContent = event.target.innerText;
    let title = "";
    if (groupID > -1) title = this.column.items[groupID].title;
    else title = this.column.title;
    if (title != newContent) {
      title = newContent;
    }
    // TO DO
    // localhost
    // send update to server DB
  }

  addNewItem(col) {
    // console.log(id)
    const len = this.column.items.length - 1;
    // console.log(this.column.items[len]);

    this.column.items[len].items?.push(new Tile());
  }

  addNewGroup(col) {
    this.column.items.push(new Group());
  }

  // selectItem(item, event) {
  //   try {
  //     if (event.srcElement.checked == true) {
  //       this.selectedItems.push(item);
  //       item.selected = true;
  //     }
  //     if (event.srcElement.checked == false) {
  //       let selectedIndex = this.selectedItems.indexOf(item);
  //       this.selectedItems.splice(selectedIndex, 1);
  //       item.selected = false;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  setItemSize(size: string): void {
    // console.log(size);
    this.itemSizeClass = size;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      // move droped item
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      // console.log(event.container.data);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  setDestination(idx, container) {
    //  console.log(idx)
    this.tile_destination_index = idx;
    this.tile_destination_container = container;
  }

  dropTile(event: CdkDragDrop<string[]>, idx: number) {
    // console.log(this.tile_destination_container)

    if (event.previousContainer.data == this.tile_destination_container) {
      // move droped item
      //  console.log(event.previousContainer.data);
      moveItemInArray(event.container.data, idx, this.tile_destination_index);
    } else {
      console.log(event.container.data);
      transferArrayItem(
        event.container.data,
        this.tile_destination_container,
        idx,
        this.tile_destination_index
      );
    }
    this.data.checkFor_hidden_tiles(0);
  }

  // getSlicePoint(): number {
  //   switch (this.itemSizeClass) {
  //     case "small":
  //       return 30;
  //     case "medium":
  //       return 25;
  //     case "large":
  //       return 15;
  //   }
  // }

  toggleExpandMode(group) {
    console.log("expandMode");
    group.expandMode = !group.expandMode;
    this.data.checkFor_hidden_tiles(0);
  }

  doAction(action: any): void {
    // try {
    //     this.actionService.doAction(action).subscribe(() => {
    //         console.log("SENT ACTION");
    //     });
    // } catch (error) {
    //     this.logError(error);
    // }
  }

  contextMenu(event, item, expandMode) {
    this.onContextMenu.emit({
      event: event,
      item: item,
      expandMode: expandMode
    });
  }

  editDialogOpen(event, item) {
    console.log("column");
    this.onEditDialogOpen.emit({
      event: event,
      item: item
    });
  }

  viewFullGroup(group) {}
}
