import { FileModel } from "./File";
import { Tile } from "./Tile";
export class Group {
  title: string;
  items: Array<Tile> | Array<FileModel>;
  hiddenTiles: boolean;
  layout: string;
  imageFit: string;
  expandMode: boolean;
  backgroundFit: string;
  constructor() {
    this.title = " . . . new group ";
    this.items = [];
    this.hiddenTiles = false;
    this.expandMode = false;
  }
}
