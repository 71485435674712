import { Injectable, Injector } from "@angular/core";
import { Layout } from "../model/layout";
import { HttpService } from "./http.service";
import { BehaviorSubject } from "rxjs";

import { Router, ActivatedRoute } from "@angular/router";

@Injectable()
export class DataService {
  private dataSource = new BehaviorSubject<Layout[]>(<Layout[]>[]);
  UIdata = this.dataSource.asObservable();
  data: Layout;
  private get _router() {
    return this._injector.get(Router);
  }

  constructor(private http: HttpService, private _injector: Injector) {
    // this.router = router;
    this.getUIcomponents();
  }

  getUIcomponents() {
    this.http.getUI().subscribe((data: Layout[]) => {
      // Read the result field from the JSON response.
      this.dataSource.next(data);
    });
  }

  async initData() {
    return new Promise((resolve, reject) => {
      this.http.getUI().subscribe(
        (data: Layout[]) => {
          this.data = data[0];
          // log(this.data);
          resolve(true);
        },
        (error: any) => {
          console.log(error);
        },
        () => {
          this._router.navigate(["/main"]);
        }
      );
      //
    });
  }
}
