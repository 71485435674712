export class OpenAction {
    method: string;
    parameters: {
        fileUri: string;
        windowName: string;
        delayRetries: number;
        delayMs: number;
    };

    public static init(): OpenAction {
        let action = new OpenAction();
        action.method = "open";
        action.parameters = {
            fileUri: undefined,
            windowName: undefined,
            delayRetries: 20,
            delayMs: 200
        };
        return action;
    }
}
