import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { AuthService } from "../shared/services/auth.service";
import { map, catchError } from "rxjs/operators";
// import {UrlConstants} from './constants/url.constants';
// import { ServerConfig } from "./ServerConfig";
// import { ToastrService } from 'ngx-toastr';
import { Observable } from "rxjs";
// import { NgxElectronService } from '../ngx-electron/ngx-electron.service';
// import { ElectronLogger } from './ElectronLogger';

@Injectable()
export class RssService {
  parser;
  constructor(
    private _http: HttpClient,
    private _authService: AuthService // private serverConfig: ServerConfig, // public electronService: NgxElectronService, // public toastrService: ToastrService
  ) {
    this.parser = new DOMParser();
  }

  getFeed(url) {
    const headers = new HttpHeaders({ "Content-Type": "text/xml" });
    headers.append("Accept", "text/xml");
    headers.append("Content-Type", "text/xml");
    return this._http
      .get("http://www.grouperf.com/rss/fiscal.rss", {
        observe: "body",
        responseType: "xml" as "json"
      })
      .pipe(
        map(xml => {
          let res;
          let json = { channel: [], title: "" };
          // console.log(xml);
          res = this.parser.parseFromString(xml, "text/xml");
          json.title = res.getElementsByTagName("title")[0].innerHTML;

          res = res.getElementsByTagName("item");

          for (var i = 0; i < res.length; i++) {
            let item = res[i].childNodes;
            json.channel[i] = {};
            for (var j = 0; j < item.length; j++) {
              json.channel[i][item[j].tagName] = item[j].innerHTML;
              // console.log(item[j]);
            }
          }
          return json;
        })
      )
      .pipe(
        catchError(error => {
          this.logError(error);
          return Observable.throw(error.json().error || "Unable to get feed");
        })
      );
  }

  showError(msg) {
    // this.toastrService.error(msg);
  }

  private logError(error: any) {
    console.error(error);
    // this.showError(error);
    // this.electronService.ipcRenderer.send(
    //   "error",
    //   ElectronLogger.FormLogMessage(error.json())
    // );
  }
}
