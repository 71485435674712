import { Component } from "@angular/core";
import { ElectronService } from "../electron/electron.service";

// import { TranslateService } from "@ngx-translate/core";
import { AppConfig } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "smoke-web";
  constructor(
    public electronService: ElectronService // public translate: TranslateService
  ) {
    // translate.setDefaultLang("en");
    console.log("AppConfig", AppConfig);
    // console.log("isElectron", electronService.isElectron);

    if (electronService.isElectron) {
      console.log(process.env);
      // console.log("Mode electron");
      // console.log("Electron ipcRenderer", electronService.ipcRenderer);
      // console.log("NodeJS childProcess", electronService.childProcess);
    } else {
      console.log("Mode web");
    }
  }
}
