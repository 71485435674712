import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
@Component({
  selector: "menuComponent",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent {
  @Output() itemSizeClick: EventEmitter<any> = new EventEmitter();
  @Output() toggleFiles: EventEmitter<any> = new EventEmitter();
  @Output() removeFilesClick: EventEmitter<Array<any>> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() group: any;
  openMenu = false;
  showFiles = false;
  setItemSize(value: string) {
    this.itemSizeClick.emit(value);
  }

  removeFiles(value: Array<any>) {
    this.removeFilesClick.emit(value);
  }

  handleChange(file: any, group: any) {
    let data = { file, group };
    this.change.emit(data);
  }

  toggleShowFiles() {
    this.toggleFiles.emit();

    this.showFiles = !this.showFiles;
    // if (!this.showFiles) this.openMenu = false;
  }
}
