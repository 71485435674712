import "reflect-metadata";
import "../polyfills";

import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HttpService } from "./shared/services/http.service";
import { DataService } from "./shared/services/data.service";
import { DataStoreService } from "./shared/services/datastore.service";

import { ElectronService } from "../electron/electron.service";
// import { SharedModule } from "./shared/shared.module";

import { AppRoutingModule } from "./app-routing.module";

import { ContextMenuModule } from "ngx-contextmenu";
import { AppComponent } from "./app.component";
import { MainModule } from "./main/main.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

export function dataProviderFactory(provider: DataService) {
  return () => provider.initData();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ContextMenuModule.forRoot(),
    BrowserModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MainModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: dataProviderFactory,
      deps: [DataService],
      multi: true,
    },
    DataService,
    HttpService,
    DataStoreService,
    ElectronService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
