import { Component, OnInit, Input } from "@angular/core";

import { FileModel } from "../../shared/model/layout/File";
import { OpenAction } from "../../shared/model/actions/OpenAction";

import { appearAnimation } from "../../shared/animations/appear.animation";
import { fadeInAnimation } from "../../shared/animations/fade-in.animation";

@Component({
  selector: "file-component",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"]
})
export class FileComponent implements OnInit {
  @Input() group: any;
  focusedInput: string;
  itemSizeClass: string = "medium";
  selectedItems: Array<any> = [];

  showFiles = false;

  constructor() {}

  ngOnInit(): void {}

  getSlicePoint(): number {
    switch (this.itemSizeClass) {
      case "small":
        return 30;
      case "medium":
        return 25;
      case "large":
        return 15;
    }
  }

  doAction(action: any): void {
    try {
      // this.actionService.doAction(action).subscribe(() => {
      //   console.log("SENT ACTION");
      // });
    } catch (error) {
      this.logError(error);
    }
  }

  selectItem(item, event) {
    try {
      if (event.srcElement.checked == true) {
        this.selectedItems.push(item);
        item.selected = true;
      }
      if (event.srcElement.checked == false) {
        let selectedIndex = this.selectedItems.indexOf(item);
        this.selectedItems.splice(selectedIndex, 1);
        item.selected = false;
      }
    } catch (error) {
      this.logError(error);
    }
  }

  addFile(eventData: any) {
    if (eventData && eventData.file && eventData.group) {
      let file = eventData.file;
      let group = eventData.group;
      // this.actionService
      //   .addFile(this.createFileObject(file, group.id))
      //   .subscribe(
      //     data => {
      //       if (typeof group.items == "undefined") {
      //         group.items = [];
      //       }
      //       // .json()
      //       group.items.push(data);
      //       (document.getElementById("fileSelect") as any).value = null;
      //     },
      //     (error: any) => {
      //       this.logError(error);
      //     }
      //   );
    }
  }

  openFile(file: any, event): void {
    try {
      let action = this.createOpenAction(file);
      this.doAction(action);
    } catch (error) {
      this.logError(error);
    }
  }

  getUrlImage(item) {
    if (!item.path.split(".")[1]) return "assets/images/folder.png";

    return item.imageURL;
  }

  removeFiles(list: Array<any>) {
    try {
      if (this.selectedItems.length) {
        var allItems = list;
        var selectedItems = this.selectedItems;
        // this.actionService.removeFiles(this.selectedItems).subscribe(
        //   res => {
        //     for (let i = 0; i < selectedItems.length; i++) {
        //       let index = allItems.indexOf(selectedItems[i]);
        //       allItems.splice(index, 1);
        //     }
        //     list = allItems.concat(res);
        //     this.selectedItems = [];
        //   },
        //   (error: any) => {
        //     this.logError(error);
        //   }
        // );
      }
    } catch (error) {
      this.logError(error);
    }
  }

  createFileObject(file: any, groupId: string): FileModel {
    try {
      let object = new FileModel();
      object.toGroupId = groupId;
      object.title = file.name;
      object.path = file.path;
      object.lastModified = file.lastModified;
      object.mimeType = file.type;
      object.size = file.size;
      return object;
    } catch (error) {
      this.logError(error);
    }
  }

  createOpenAction(file: any): OpenAction {
    try {
      let action = OpenAction.init();
      action.parameters.fileUri = file.path;
      action.parameters.windowName = file.name;
      return action;
    } catch (error) {
      this.logError(error);
    }
  }

  editTitle(item, index, event) {
    event.stopPropagation();
    try {
      if (document.getElementById(this.focusedInput) != null) {
        document.getElementById(this.focusedInput).blur();
      }
      item.isEditing = true;
      item.initialTitle = item.title;
      this.focusEditField(index, item);
      document.addEventListener(
        "keydown",
        e => {
          let isEscape = e.key == "Escape" || e.key == "Esc" || e.keyCode == 27;
          let isEnter = e.keyCode == 13;
          if (isEscape) {
            item.title = item.initialTitle;
            item.isEditing = false;
          } else if (isEnter) {
            this.saveEditTitle(item, index);
          }
        },
        false
      );
    } catch (error) {
      this.logError(error);
    }
  }

  saveEditTitle(item, index) {
    // console.log(item)
    try {
      let updatedItem = item;
      if (document.getElementById("editInput" + index) != null) {
        updatedItem.title = (document.getElementById(
          "editInput" + index
        ) as any).value;
      }
      // this.actionService.editFile(updatedItem as FileModel).subscribe(
      //   res => {
      //     if (res.ok) {
      //       // item.title = res.title;
      //       item.isEditing = false;
      //       item.initialTitle = item.title;
      //     }
      //   },
      //   (err: any) => {
      //     console.error(err);
      //     this.showError(err);
      //   }
      // );
    } catch (error) {
      this.logError(error);
    }
  }

  focusEditField(index, item) {
    try {
      this.focusedInput = "editInput" + index;
      setTimeout(() => {
        let editInput = document.getElementById("editInput" + index) as any;
        editInput.value = item.title;
        editInput.focus();
      }, 20);
    } catch (error) {
      this.logError(error);
    }
  }

  viewFullGroup(group) {
    try {
      // this.onGroupExpand.emit(group);
    } catch (error) {
      this.logError(error);
    }
  }

  private logError(error: any) {
    // console.log(error);
    this.logItem(error);
    this.showError(error);

    // this.electronService.ipcRenderer.send(
    //     "error",
    //     ElectronLogger.FormLogMessage(error)
    // );
  }

  showError(msg) {
    // this.toastrService.error(msg);
  }

  logItem(item) {
    console.log(item);
  }
}
