import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// app components
import { MainComponent } from "./main/main.component";

const routes: Routes = [
  {
    path: "main",
    component: MainComponent,
    pathMatch: "full"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: "reload"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
