import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError, config } from "rxjs";
import { catchError, retry } from "rxjs/operators";

import { AppConfig } from "../../../environments/environment";

@Injectable()
export class HttpService {
  baseUrl = AppConfig.baseUrl;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      ApiKey: "c7a919e51f2268e5c8eb471ae093d15d"
    })
  };

  constructor(private http: HttpClient) {}

  private get(url: string, data: string = ""): Observable<any> {
    return this.http.post(this.baseUrl + url, data, this.httpOptions);
  }

  private set(url: string, data: string = ""): Observable<any> {
    return this.http.put(this.baseUrl + url, data, this.httpOptions);
  }

  public getUI(): Observable<any> {
    // console.log("http.getUI()")
    return this.http.get("./assets/pages.json");
  }

  public setUi(data): Observable<any> {
    return this.set("/assets/", data);
  }
}
