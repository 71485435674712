import { Column } from "./Column";
import { Toolbar } from "./Toolbar";
export class Layout {
  title: string;
  layout: string;
  imageURL: string;
  columns: Array<Column>;
  items: Array<any>;
  textColor: string;
  toolbar: Toolbar;
}
