import { Component, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Tile } from "../../shared/model/layout";

@Component({
  selector: "tileBtn",
  templateUrl: "./tileBtn.component.html",
  styles: [".tile-small {background-color: transparent}"]
})
export class TileBtnComponent {
  @Input() tile: Tile;

  constructor() {}

  setTooltip(desc) {
    let el = document.getElementsByClassName("mat-tooltip");
    if (!el.length || !desc.length) return;
    setTimeout(() => {
      if (el[0] != undefined) el[0].innerHTML = desc;
    }, 120);
    //
  }
}
